<template>
  <div class="home">
    <h1>Accueil</h1>
    <p>Bienvenue sur le SYSTOOLS Hexa Gaming !</p>
    <p>C'est ici que tu pourra faire tes demande d'accès aux différentes applications de l'intranet Hexa-Gaming !</p>
    <p>Tu peux te connecter <a href="/login">ICI</a> !</p>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
